import React from "react";
import { Link } from "react-router-dom";

export default function NewsBanner () {


    return <div class='w-full bg-bja-secondary text-white text-center py-[30px] px-5 flex flex-col gap-[20px] justify-center font-bold uppercase'>
        <p class='text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] tracking-wider'>Legújabb híreinket itt tudja megnézni!</p>
        <Link to='/hirek' class='bg-bja-primary self-center w-fit uppercase text-bold py-[5px] px-[15px] drop-shadow-white-sm sm:text-[24px] sm:py-[10px] sm:px-[20px] sm:drop-shadow-white-lg sm:hover:drop-shadow-primary-lg hover:bg-white hover:text-bja-primary hover:drop-shadow-primary-sm lg:py-[20px] lg:px-[50px] lg:text-2xl lg:drop-shadow-white-lg lg:hover:drop-shadow-primary-lg'>Híreink</Link>
    </div>
}