import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


export default function Games() {
    const { team } = useParams();
    const [data, setData] = useState([])

    useEffect(() => {
        fetch(process.env.REACT_APP_BLOGGER_URL + process.env.REACT_APP_BLOGGER_BLOGID + '/posts?labels=' + team.toLowerCase() + '&key=' + process.env.REACT_APP_BLOGGER_KEY)
            .then(response => {
                if (!response.ok) {
                    console.error(response)
                    throw new Error('response not OK')
                }
                return response.json()
            })
            .then(data => {
                if (data.items) {
                    parseContent(data.items[0].content)
                } else {
                    console.error('no posts found')
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
    }, [])

    const parseContent = (pageContent) => {
        const doc = new DOMParser().parseFromString(pageContent, 'text/html')
        const lines = doc.querySelectorAll('p')

        const tableRows = Array.from(lines).map(p => {
            const text = p.textContent.trim();
            if (text) {
                const [home, away, location, date, score] = text.split(',').map(field => field.trim())
                return { home, away, location, date, score }
            }
            return null
        })

        setData(tableRows)
    }


    return <>
        <h1 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-bja-secondary tracking-wider'>{team} Mérkőzések</h1>
        <div class='bg-white px-[10px] py-[20px] md:py-[50px] text-xs sm:text-base overflow-x-scroll'>
            <table class='socreboard max-w-[800px] text-center text-bja-primary mx-auto '>
                <thead class='font-bold bg-bja-primary text-bja-secondary'>
                    <tr>
                        <th>Hazai csapat</th>
                        <th>Vendég csapat</th>
                        <th>Helyszín</th>
                        <th>Dátum</th>
                        <th>Eredmény</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row) => {
                            return (
                                <tr class='even:bg-bja-primary even:text-white'>
                                    <td>{row.home}</td>
                                    <td>{row.away}</td>
                                    <td>{row.location}</td>
                                    <td>{row.date}</td>
                                    <td>{row.score}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    </>
}