import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function NewsPost() {

    const { id } = useParams()
    const apiSlug = 'https://www.googleapis.com/blogger/v3/blogs/'
    const [postData, setPostData] = useState(null)

    useEffect(() => {
        fetch(apiSlug + process.env.REACT_APP_BLOGGER_BLOGID + '/posts/' + id + '?key=' + process.env.REACT_APP_BLOGGER_KEY)
            .then(response => {
                if (!response.ok) {
                    // console.log(response)
                    throw new Error('response not OK')
                }
                return response.json()
            })
            .then(data => {
                if (data) {
                    setPostData(data)
                } else {
                    throw new Error('No post data found')
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
    }, [])

    const cleanContent = (postContent) => {
        const cleanedContent = postContent.replace(/&nbsp;/, '')
        const doc = new DOMParser().parseFromString(cleanedContent, 'text/html')
        doc.querySelectorAll('*[style]').forEach((el) => el.removeAttribute('style'))
        return doc.body.innerHTML
    }

    return <>
        <div class='w-full bg-white flex flex-col items-center'>
            <div class='max-w-[1400px] w-full flex flex-col items-center py-[20px] md:py-[50px] px-[20px]'>

                {
                    postData !== null ? (
                        <>
                            <h1 class='small-caps text-bja-secondary font-semibold text-[32px]'>{postData.title}</h1>
                            <div class='flex items-center w-full my-[15px]'>
                                <div class='flex-grow border-t border-bja-primary'></div>
                                <span class='mx-2 text-bja-primary small-caps text-sm md:text-base'>{postData.published.slice(0, 10)} | {postData.labels.filter(label => label !== 'hírek').join(', ').toUpperCase()}</span>
                                <div class='flex-grow border-t border-bja-primary'></div>
                            </div>
                            <section class='news-content w-full' dangerouslySetInnerHTML={{ __html: cleanContent(postData.content)}}></section>

                        </>
                    ) : (
                        <p class='text-black'>Hír betöltése</p>
                    )


                }
            </div>
        </div>

    </>
}