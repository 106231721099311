import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

export default function Players() {

    const { team } = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        const fetchJson = async () => {
            try {
                const response = await import(`../teamData/${team}.json`)
                if (Object.keys(response).length === 0) {
                    throw new Error('no player data')
                }
                setData(response.default)
            } catch (err) {
                throw new Error('something went wrong when fetching player data')
            }
        }

        fetchJson()

    }, [team])


    return <>
        <h1 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-bja-secondary tracking-wider'>{team==='noi' ? 'Női' : team}</h1>
        <div class=' flex justify-center'>
            <img src={require(`../assets/teams/${team}/team.jpg`)} alt={`${team} team photo`} class='mx-auto w-full aspect-[19/7] object-cover overflow-hidden' />
        </div>
        
        <h2 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-white tracking-wider'>Edzők</h2>
        <div class=' bg-white py-[60px]'>
            <div class='max-w-[1600px] mx-auto justify-center flex flex-wrap gap-[20px] md:gap-[60px] py-[10px] px-[10px] sm:px-[20px]'>
            {
                data !== null ? (
                    data.coaches.map((coach) => {
                        return <div class='bg-bja-primary text-center small-caps font-semibold pb-[10px] w-1/4 max-w-[300px] min-w-[100px]'>
                            <div class='relative'>
                                <div class='absolute h-full w-full bg-gradient-to-t from-bja-primary to-40%'></div>
                                <img src={require(`../assets/teams/${team}/${coach.img}`)} alt={coach.name} class='w-full max-w-[300px]' />
                            </div>
                            <p class='text-white text-sm sm:text-lg md:text-xl lg:text-2xl px-1'>{coach.name}</p>
                            <p class='text-bja-secondary text-xs sm:text-sm md:text-base lg:text-lg px-1'>{coach.role}</p>
                        </div>
                    })
                ) : (
                    <p class='text-black'>Edzők betöltése...</p>
                )
            }
            </div>
        </div>
        <h2 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-white tracking-wider'>Játékosok</h2>
        <div class='bg-white py-[60px]'>
        <div class='max-w-[1600px] mx-auto justify-center flex flex-wrap gap-[20px] md:gap-[60px] py-[10px] px-[10px] sm:px-[20px]'>
            {
                data !== null ? (
                    data.players.map((player) => {
                        return <div class='bg-bja-primary text-center small-caps font-semibold pb-[10px] w-1/4 max-w-[300px] min-w-[100px]'>
                            <div class='relative'>
                                <div class='absolute h-full w-full bg-gradient-to-t from-bja-primary to-40%'></div>
                                <img src={require(`../assets/teams/${team}/${player.img}`)} alt={player.name} class='w-full max-w-[300px]' />
                            </div>
                            <p class='text-white text-sm sm:text-lg md:text-xl lg:text-2xl px-1'>{player.name}</p>
                            <p class='text-bja-secondary text-xs sm:text-sm md:text-base lg:text-lg px-1'>{player.role}</p>
                        </div>
                    })
                ) : (
                    <p class='text-black'>Játékosok betöltése...</p>
                )
            }
            </div>
        </div>
    </>
}