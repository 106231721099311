import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function ContactUs() {

    return <>
        <h2 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-bja-secondary tracking-wider'>Kapcsolat</h2>
        <div class='w-full bg-white'>
            <div class='max-w-[1600px] flex flex-col mx-auto px-[20px]'>
                <div class='flex flex-col md:flex-row gap-[25px] text-bja-primary text-[16px] lg:text-[20px] font-bold self-center py-[20px] md:py-[50px]'>
                    <Link to='mailto:akademia@bja.sport.hu' class='flex gap-[10px]'>
                        <FontAwesomeIcon icon={faEnvelope} class='h-[40px] aspect-square place-self-center'/>
                        <p class='place-self-center'>akademia@bja.sport.hu</p>
                    </Link>
                    <div class='flex gap-[10px]'>
                        <FontAwesomeIcon icon={faLocationDot} class='h-[40px] aspect-square place-self-center'/>
                        <p class='place-self-center'>Tüskecsarnok<br></br>1117 Bp. Magyar Tudósok Körútja 7.</p>
                    </div>
                </div>
                <h2 class='text-bja-primary small-caps text-[28px] md:text-[36px] lg:text-[40px] font-bold mx-auto pb-[10px]'>Találj meg minket a Google Maps-en</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.827757463493!2d19.055463577593727!3d47.47206027117782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dda977fc4cbb%3A0xdd18d9fb7073827d!2sT%C3%BCskecsarnok!5e0!3m2!1sen!2shu!4v1725366224603!5m2!1sen!2shu" class='pb-[20px] w-full aspect-[9/5]' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </>

}
