import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import News from './pages/News';
import NewsPost from './pages/NewsPost';
import Teams from './pages/Teams';
import Programmes from './pages/Programmes';
import ContactUs from './pages/ContactUs';
import Players from './pages/Players';
import Games from './pages/Games';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavWrapper />,
    // errorElement: '',
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/hirek',
        element: <News />
      },
      {
        path: '/hirek/poszt/:id',
        element: <NewsPost />
      },
      {
        path: '/csapatok-es-merkozesek',
        element: <Teams />
      },
      {
        path: '/programok',
        element: <Programmes />
      },
      {
        path: '/kapcsolat',
        element: <ContactUs />
      },
      {
        path: '/jatekosok/:team',
        element: <Players />
      },
      {
        path: '/merkozesek/:team',
        element: <Games />
      }
    ]
  }
])


function NavWrapper() {
  return (
    <div class='bg-bja-primary'>
    <ScrollRestoration />
    <Nav />
    <Outlet />
    <Footer />
    </div>
  )
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback='loading'>
      <RouterProvider router={router} />
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
