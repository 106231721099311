import React, { useState, useEffect } from "react";
import placeholder from '../assets/placeholder.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";





export default function News() {

    const apiSlug = 'https://www.googleapis.com/blogger/v3/blogs/'

    const postsPerPage = 5

    const [posts, setPosts] = useState([])
    const [nextPageToken, setNextPageToken] = useState(null)
    const [pageTokenStack, setPageTokenStack] = useState([])

    useEffect(() => {

        fetch(`${apiSlug}${process.env.REACT_APP_BLOGGER_BLOGID}/posts?labels=hírek&maxResults=${postsPerPage}&key=${process.env.REACT_APP_BLOGGER_KEY}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('response not OK')
                }
                return response.json()
            })
            .then(data => {
                if (data.items) {
                    setPosts(data.items)
                    if (data.nextPageToken) { setNextPageToken(data.nextPageToken) }
                } else {
                    throw new Error('No posts found')
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
    }, [])

    const nextPage = () => {
        setPageTokenStack([nextPageToken, ...pageTokenStack])

        fetch(`${apiSlug}${process.env.REACT_APP_BLOGGER_BLOGID}/posts?labels=hírek&maxResults=${postsPerPage}&pageToken=${nextPageToken}&key=${process.env.REACT_APP_BLOGGER_KEY}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('response not OK')
                }
                return response.json()
            })
            .then(data => {
                if (data.items) {
                    setPosts(data.items)
                    if (data.nextPageToken) { setNextPageToken(data.nextPageToken) } else { setNextPageToken(null) }
                } else {
                    throw new Error('No posts found')
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
    }

    const prevPage = () => {

        let pageToken = ''

        const lastPage = pageTokenStack[0]
        setPageTokenStack(pageTokenStack.filter(token => token !== lastPage))

        if (pageTokenStack.length > 1) {
            pageToken = '&pageToken=' + pageTokenStack[1]
        }

        fetch(`${apiSlug}${process.env.REACT_APP_BLOGGER_BLOGID}/posts?labels=hírek&maxResults=${postsPerPage}${pageToken}&key=${process.env.REACT_APP_BLOGGER_KEY}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('response not OK')
                }
                return response.json()
            })
            .then(data => {
                if (data.items) {
                    setPosts(data.items)
                    if (data.nextPageToken) { setNextPageToken(data.nextPageToken) }
                } else {
                    throw new Error('No posts found')
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)
            })
    }

    const cleanContent = (postContent) => {

        const cleanedContent = postContent.replace(/&nbsp;/, '')

        const doc = new DOMParser().parseFromString(cleanedContent, 'text/html')

        const img = doc.querySelector('img')
        const firstImg = img ? img.src : null;



        doc.querySelectorAll('img').forEach(img => img.remove())
        doc.querySelectorAll('*[style]').forEach((el) => el.removeAttribute('style'))

        const textContent = doc.body.innerHTML

        return { firstImg, textContent }
    }

    const ReadMoreButton = ({ postId }) => {
        return <Link to={`/hirek/poszt/${postId}`} class='bg-bja-primary text-white font-bold sm:text-[16px] lg:drop-shadow-secondary-lg lg:px-[25px] lg:py-[15px] drop-shadow-secondary-sm px-[15px] py-[5px] text-[14px] text-bold uppercase w-fit self-center hover:bg-bja-secondary hover:drop-shadow-primary-sm lg:hover:drop-shadow-primary-lg hover:text-white' >Elolvasom</Link>
    }

    const postNo = 0
    return <>
        <h1 class='text-center mx-auto lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-bja-secondary tracking-wider'>Hírek</h1>
        <div class='bg-white sm:px-[20px] lg:px-[50px] py-[20px] md:py-[20px] lg:py-[50px] gap-[20px] md:gap-[50px] flex flex-col'>
            <div class='flex flex-col'>
                {
                    posts.length > 0 ? (
                        posts.map((post) => {
                            const { firstImg, textContent } = cleanContent(post.content)

                            return (
                                <div key={post.id} class={`mx-auto max-w-[1600px] w-full max-h-[450px] h-full flex md:odd:flex-row md:even:flex-row-reverse flex-col`}>

                                    <img src={firstImg ? firstImg : placeholder} class='md:w-1/2 h-[250px] md:h-auto overflow-hidden object-cover' />

                                    <div class='md:w-1/2 flex flex-col gap-[15px] lg:gap-[30px] items-center p-[20px] md:p-[50px]'>
                                        <h2 class='text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] text-bja-secondary small-caps text-center font-semibold'>{post.title}</h2>
                                        <div class='flex items-center w-full'>
                                            <div class='flex-grow border-t border-bja-primary'></div>
                                            <span class='mx-2 text-bja-primary small-caps text-sm md:text-base'>{post.published.slice(0, 10)} | {post.labels.filter(label => label !== 'hírek').join(', ').toUpperCase()}</span>
                                            <div class='flex-grow border-t border-bja-primary'></div>
                                        </div>
                                        <p class='news-content text-justify w-full overflow-hidden text-ellipsis line-clamp-4 md:line-clamp-6 lg:line-clamp-4' dangerouslySetInnerHTML={{ __html: textContent }}></p>
                                        <ReadMoreButton postId={post.id} />
                                    </div>

                                </div>
                            )


                        })
                    ) : (
                        <p class='text-black'>Hírek betöltése...</p>
                    )
                }

            </div>
            <div class='self-center flex gap-[20px] md:gap-[50px]'>
                <Link onClick={prevPage} class={`bg-bja-primary text-white font-bold sm:text-[16px] lg:drop-shadow-secondary-lg lg:px-[25px] lg:py-[15px] drop-shadow-secondary-sm px-[15px] py-[5px] text-[14px] text-bold uppercase w-fit self-center hover:bg-bja-secondary hover:drop-shadow-primary-sm lg:hover:drop-shadow-primary-lg hover:text-white ${pageTokenStack.length == 0 ? 'hidden' : 'block'}`}><FontAwesomeIcon icon={faArrowLeft} /></Link>
                <Link onClick={nextPage} class={`bg-bja-primary text-white font-bold sm:text-[16px] lg:drop-shadow-secondary-lg lg:px-[25px] lg:py-[15px] drop-shadow-secondary-sm px-[15px] py-[5px] text-[14px] text-bold uppercase w-fit self-center hover:bg-bja-secondary hover:drop-shadow-primary-sm lg:hover:drop-shadow-primary-lg hover:text-white ${nextPageToken ? 'block' : 'hidden'}`}><FontAwesomeIcon icon={faArrowRight} /></Link>
            </div>
        </div>
    </>

}