import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import logo from '../assets/logo.png'
import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default function Nav() {

    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen)
    }

    menuIsOpen ? disableBodyScroll(document) : enableBodyScroll(document)

    return (
        <>
            <nav className="bg-bja-primary w-full h-[50px] md:h-[75px] p-2 flex justify-center fixed z-50">
                <div className="h-full max-w-[760px] lg:max-w-[1600px] w-full justify-between items-center flex">
                    <Link to={'/'} onClick={menuIsOpen ? toggleMenu : null} className="h-full px-[20px]">
                        <img src={logo} alt='BJA logo' className="h-full w-auto" />
                    </Link>
                    <div className="justify-around items-center space-x-6 text-white text-[12px] md:text-[16px] lg:text-2xl uppercase hidden sm:flex">
                        <Link to={'/hirek'} className="hover:text-bja-secondary">hírek</Link>
                        <Link to={'/csapatok-es-merkozesek'} className="hover:text-bja-secondary">csapatok és mérkőzések</Link>
                        {/* <Link to={'/programok'} className="hover:text-bja-secondary">programok</Link> */}
                        <Link to={'/kapcsolat'} className="hover:text-bja-secondary">kapcsolat</Link>
                    </div>
                    <div className="p-[10px] w-[75px] lg:w-[95px] justify-around items-center hidden sm:flex" >
                        <a href="https://www.facebook.com/hokiakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className="h-5 lg:h-7 text-bja-secondary hover:text-white" /></a>
                        <a href="https://www.instagram.com/budapestjegkorongakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className="h-5 lg:h-7 text-bja-secondary hover:text-white" /></a>
                    </div>
                    <a onClick={toggleMenu} class='sm:hidden'><FontAwesomeIcon icon={faBars} class='text-white h-7 px-[20px]' /></a>

                </div>


            </nav>
            <div id='hamburger-menu' class='relative sm:hidden'>
                <div class={`fixed top-0 left-0 right-0 h-full pt-[50px] z-40 bg-bja-primary text-white transform ${menuIsOpen ? 'translate-y-0' : '-translate-y-full'} transition-transform duration-300 ease-in-out flex flex-col text-[24px] uppercase`}>
                    <Link to={'/hirek'} onClick={toggleMenu} className="hover:text-bja-secondary w-full text-center border-t-[1px] border-bja-secondary py-[10px]">hírek</Link>
                    <Link to={'/csapatok-es-merkozesek'} onClick={toggleMenu} className="hover:text-bja-secondary w-full text-center border-t-[1px] border-bja-secondary py-[10px]">csapatok és mérkőzések</Link>
                    {/* <Link to={'/programok'} onClick={toggleMenu} className="hover:text-bja-secondary w-full text-center border-t-[1px] border-bja-secondary py-[10px]">programok</Link> */}
                    <Link to={'/kapcsolat'} onClick={toggleMenu} className="hover:text-bja-secondary w-full text-center border-y-[1px] border-bja-secondary py-[10px]">kapcsolat</Link>

                    <div className="p-[10px] w-[95px] justify-around items-center flex mt-auto mb-5 self-center" >
                        <a href="https://www.facebook.com/hokiakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className="h-7 text-bja-secondary" /></a>
                        <a href="https://www.instagram.com/budapestjegkorongakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className="h-7 text-bja-secondary" /></a>
                    </div>
                </div>
            </div>
            <div class='h-[50px]'></div>


        </>
    )
}