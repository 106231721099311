import React from "react";
import placeholder from '../assets/placeholder.png'
import { Link } from "react-router-dom";

export default function AboutUs() {


    return <div class='flex flex-col items-center text-justify text-[14px] sm:text-[20px] lg:leading-[30px]'>
        <h2 class='text-[24px] sm:text-[28px] md:text-[32px] lg:text-[40px] tracking-wider text-white font-bold uppercase text-center pt-[20px] lg:pt-[50px] bg-bja-primary'>Az akadémiáról</h2>
        <div class='w-full text-white flex justify-center'>
            <section class='py-[50px] px-5 max-w-[1600px] lg:h-[620px] hidden lg:flex'>
                <div class='w-1/2 h-full px-[50px] xl:px-[100px] flex flex-col gap-[10px] justify-center'>
                    <h3 class='text-bja-secondary uppercase text-[36px]'>Történetünk</h3>
                    <p>A Budapest Jégkorong Akadémia a Hokiklub Budapest és a MAC Budapest Jégkorong Akadémia Egyesület által közösen alapított, az Emberi Erőforrások Minisztériuma Sportért Felelős Államtitkársága javaslata alapján elismert sportakadémia. Sportakadémiánk a két sikeres utánpótlás egyesület nevelési koncepcióira épült, önálló független szervezetként.</p>
                </div>
                <img alt='' src={placeholder} class='drop-shadow-secondary-xl w-1/2 h-full overflow-hidden object-cover' />
            </section>

            <section class='py-[10px] px-5 flex flex-col gap-[10px] max-w-[760px] lg:hidden'>
                <h3 class='text-bja-secondary uppercase text-[20px] text-center sm:text-[28px]'>Történetünk</h3>
                <img alt='' src={placeholder} class='drop-shadow-secondary-lg pt-[5px] pl-[5px] w-full text-yellow-400' />
                <p>A Budapest Jégkorong Akadémia a Hokiklub Budapest és a MAC Budapest Jégkorong Akadémia Egyesület által közösen alapított, az Emberi Erőforrások Minisztériuma Sportért Felelős Államtitkársága javaslata alapján elismert sportakadémia. Sportakadémiánk a két sikeres utánpótlás egyesület nevelési koncepcióira épült, önálló független szervezetként.</p>
            </section>
        </div>



        <div class='bg-white w-full text-bja-primary flex justify-center'>
            <section class='mx-auto py-[50px] px-5 max-w-[1600px] hidden lg:h-[620px] lg:flex'>
                <img alt='' src={placeholder} class='drop-shadow-secondary-xl w-1/2 h-full overflow-hidden object-cover' />
                <div class='w-1/2 h-full px-[50px] xl:px-[100px] flex flex-col gap-[10px] justify-center'>
                    <h3 class='text-bja-secondary uppercase text-[36px]'>Célunk</h3>
                    <p>Akadémiánk egy olyan elitképző, ahol fontosnak tartjuk, hogy a nálunk sportoló fiúk és lányok egy életre szóló pozitív élménnyel gazdagodjanak. Hisszük, hogy ezt csak a legmagasabb szakmai színvonal biztosításával érhetjük el. Kiváló, magasan képzett hazai és külföldi edzőink munkáját éppen ezért a legmagasabb szintű orvosi, diagnosztikai, rehabilitációs és sportegészségügyi programmal támogatjuk.<br />Célunk, hogy akadémistáink részéré mind a magyar 1. osztály, mind a külföldi top ligák felé biztosított legyen a karrier lehetősége.</p>
                </div>
            </section>

            <section class='py-[10px] px-5 flex flex-col gap-[10px] max-w-[760px] lg:hidden'>
                <h3 class='text-bja-secondary uppercase text-[20px] text-center sm:text-[28px]'>Célunk</h3>
                <img alt='' src={placeholder} class='drop-shadow-secondary-lg pt-[5px] pl-[5px] w-full' />
                <p>Akadémiánk egy olyan elitképző, ahol fontosnak tartjuk, hogy a nálunk sportoló fiúk és lányok egy életre szóló pozitív élménnyel gazdagodjanak. Hisszük, hogy ezt csak a legmagasabb szakmai színvonal biztosításával érhetjük el. Kiváló, magasan képzett hazai és külföldi edzőink munkáját éppen ezért a legmagasabb szintű orvosi, diagnosztikai, rehabilitációs és sportegészségügyi programmal támogatjuk.<br />Célunk, hogy akadémistáink részéré mind a magyar 1. osztály, mind a külföldi top ligák felé biztosított legyen a karrier lehetősége.
                </p>
            </section>
        </div>



        <div class='w-full text-white flex justify-center'>
            <section class='mx-auto py-[50px] px-5 max-w-[1600px] hidden lg:h-[620px] lg:flex'>
                <div class='w-1/2 h-full px-[50px] xl:px-[100px] flex flex-col gap-[10px] justify-center'>
                    <h3 class='text-bja-secondary uppercase text-[36px]'>Csapataink</h3>
                    <p>Míg a két U16-os csapatunk a magyar bajnokságban szerepel, U18-as, U21-es és női, csapataink a magas minőségű játéklehetőség biztosítása érdekében a magyar bajnokságok mellett az osztrák szövetség által szervezett nemzetközi bajnokságokban is szerepelnek.<br />Fontosnak tartjuk, hogy mindenki a saját fizikai képességeinek és játék tudásának megfelelő szinten játsszon, így a feljátszás lehetőségét minden korosztályban biztosítjuk játékosainknak. Akár már preakadémistaként is lehetőség nyílhat az U15-ös csapatban való szereplésre, míg kiemelkedő idősebb játékosaink lehetőséget kaphatnak az Erste Ligában szereplő Budapest Jégkorong Akadémia Hockey Club-nál.</p>
                    <Link to='/csapatok-es-merkozesek' class='bg-white text-bja-primary font-bold text-2xl drop-shadow-secondary-lg px-[25px] py-[15px] uppercase w-fit self-center hover:bg-bja-secondary hover:drop-shadow-white-lg hover:text-white'>Bővebben</Link>
                </div>
                <img alt='' src={placeholder} class='drop-shadow-secondary-xl w-1/2 h-full overflow-hidden object-cover' />
            </section>

            <section class='py-[10px] px-5 flex flex-col gap-[10px] max-w-[760px] lg:hidden'>
                <h3 class='text-bja-secondary uppercase text-[20px] text-center sm:text-[28px]'>Csapataink</h3>
                <img alt='' src={placeholder} class='drop-shadow-secondary-lg pt-[5px] pl-[5px] w-full' />
                <p>Míg a két U16-os csapatunk a magyar bajnokságban szerepel, U18-as, U21-es és női, csapataink a magas minőségű játéklehetőség biztosítása érdekében a magyar bajnokságok mellett az osztrák szövetség által szervezett nemzetközi bajnokságokban is szerepelnek.<br />Fontosnak tartjuk, hogy mindenki a saját fizikai képességeinek és játék tudásának megfelelő szinten játsszon, így a feljátszás lehetőségét minden korosztályban biztosítjuk játékosainknak. Akár már preakadémistaként is lehetőség nyílhat az U15-ös csapatban való szereplésre, míg kiemelkedő idősebb játékosaink lehetőséget kaphatnak az Erste Ligában szereplő Budapest Jégkorong Akadémia Hockey Club-nál.
                </p>
                <Link to='/csapatok-es-merkozesek' class='bg-white text-bja-primary font-bold text-[16px] sm:text-[24px] py-[5px] px-[15px] sm:py-[10px] sm:px-[20px] sm:drop-shadow-secondary-lg sm:hover:drop-shadow-white-lg uppercase drop-shadow-secondary-sm mx-auto hover:bg-bja-secondary hover:drop-shadow-white-sm hover:text-white'>Bővebben</Link>
            </section>
        </div>
    </div>
}