import React from "react";
import { Link } from "react-router-dom";
import placeholder from '../assets/placeholder.png'

export default function tos() {

    const Button = ({ to, color, children }) => {

        const baseClass = 'font-bold uppercase w-fit lg:text-[24px] xl:text-[32px] xl:px-[30px] lg:py-[15px] xl:py-[20px] px-[15px] py-[5px] text-[14px] lg:drop-shadow-secondary-lg drop-shadow-secondary-sm hover:bg-bja-secondary'

        const colors = color === 'primary'
            ? 'bg-bja-primary text-white hover:lg:drop-shadow-primary-lg hover:drop-shadow-primary-sm'
            : 'bg-white text-bja-primary hover:lg:drop-shadow-white-lg hover:drop-shadow-white-sm hover:text-white'

        return <Link to={to} class={`${baseClass} ${colors}`} >{children}</Link>
    }




    return <div class='flex flex-col'>
        <h2 class='text-center mx-auto py-[10px] lg:py-[50px] uppercase text-[20px] sm:text-[28px] lg:text-[40px] font-bold text-bja-secondary tracking-wider'>Csapatok</h2>
        <div class='w-full'>
            <div class='bg-white'>
                <div class='flex-col md:flex-row flex max-w-[1600px] p-[20px] md:p-[50px] items-center mx-auto'>
                    <img src={require('../assets/teams/U15/team.jpg')} class='w-full md:w-1/2 max-h-[300px] md:h-full overflow-hidden object-cover md:aspect-[6/4]' />
                    <div class='w-1/2 flex flex-col items-center'>
                        <h3 class='text-[48px] md:text-[96px] font-semibold text-bja-secondary'>U15</h3>
                        <div class='flex gap-[15px] md:gap-[30px]'>
                            <Button to='/jatekosok/U15' color='primary'>Játékosok</Button>
                            <Button to='/merkozesek/U15' color='primary'>Mérkőzések</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div class='flex-col md:flex-row flex max-w-[1600px] p-[20px] md:p-[50px] items-center mx-auto'>
                <img src={require('../assets/teams/U16/team.jpg')} class='w-full md:w-1/2 max-h-[300px] md:h-full overflow-hidden object-cover md:aspect-[6/4]' />
                <div class='w-1/2 flex flex-col items-center'>
                    <h3 class='text-[48px] md:text-[96px] font-semibold text-bja-secondary'>U16</h3>
                    <div class='flex gap-[15px] md:gap-[30px]'>
                        <Button to='/jatekosok/U16' color='white'>Játékosok</Button>
                        <Button to='/merkozesek/U16' color='white'>Mérkőzések</Button>
                    </div>
                </div>
            </div>
            <div class='bg-white'>
                <div class='flex-col md:flex-row flex max-w-[1600px] p-[20px] md:p-[50px] items-center mx-auto'>
                    <img src={require('../assets/teams/U18/team.jpg')} class='w-full md:w-1/2 max-h-[300px] md:h-full overflow-hidden object-cover md:aspect-[6/4]' />
                    <div class='w-1/2 flex flex-col items-center'>
                        <h3 class='text-[48px] md:text-[96px] font-semibold text-bja-secondary'>U18</h3>
                        <div class='flex gap-[15px] md:gap-[30px]'>
                            <Button to='/jatekosok/U18' color='primary'>Játékosok</Button>
                            <Button to='/merkozesek/U18' color='primary'>Mérkőzések</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div class='flex-col md:flex-row flex max-w-[1600px] p-[20px] md:p-[50px] items-center mx-auto'>
                <img src={require('../assets/teams/U21/team.jpg')} class='w-full md:w-1/2 max-h-[300px] md:h-full overflow-hidden object-cover md:aspect-[6/4]' />
                <div class='w-1/2 flex flex-col items-center'>
                    <h3 class='text-[48px] md:text-[96px] font-semibold text-bja-secondary'>U21</h3>
                    <div class='flex gap-[15px] md:gap-[30px]'>
                        <Button to='/jatekosok/U21' color='white'>Játékosok</Button>
                        <Button to='/merkozesek/U21' color='white'>Mérkőzések</Button>
                    </div>
                </div>
            </div>
            <div class='bg-white'>
                <div class='flex-col md:flex-row flex max-w-[1600px] p-[20px] md:p-[50px] items-center mx-auto'>
                    <img src={require('../assets/teams/noi/team.jpg')} class='w-full md:w-1/2 max-h-[300px] md:h-full overflow-hidden object-cover md:aspect-[6/4]' />
                    <div class='w-1/2 flex flex-col items-center'>
                        <h3 class='text-[48px] md:text-[96px] font-semibold text-bja-secondary'>Női</h3>
                        <div class='flex gap-[15px] md:gap-[30px]'>
                            <Button to='/jatekosok/noi' color='primary'>Játékosok</Button>
                            <Button to='/merkozesek/noi' color='primary'>Mérkőzések</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}