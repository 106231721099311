import React from "react"
import logo from '../assets/logo.png'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import biotechusa from '../assets/biotechusa.png'
import mjsz from '../assets/mjsz2.png'

export default function Footer() {


    return <>
        <div class='sponsor-bar max-w-[1600px] flex flex-wrap justify-center gap-[50px] mx-auto py-[30px]'>
            <Link to='https://nsu.hu/' target="_blank" rel="noreferrer" class='my-auto'><img src={require('../assets/nsu.png')} /></Link>
            <Link to='https://www.jegkorongszovetseg.hu/' target="_blank" rel="noreferrer" class='my-auto'><img src={mjsz} /></Link>
            <Link to='https://shop.biotechusa.hu/' target="_blank" rel="noreferrer" class='my-auto'><img src={biotechusa} class='invert' /></Link>
        </div>


        <footer class='mx-auto px-[20px] pb-[10px] max-w-[1600px] flex flex-col flex-wrap gap-[10px] justify-between uppercase text-white md:flex-row'>
            <div class=' h-full flex flex-col gap-[10px] leading-9'>
                <Link to='/' class='text-bja-secondary text-[20px] hover:text-white'>Budapest Jégkorong Akadémia</Link>
                <Link to='/hirek' class='hover:text-bja-secondary'>Hírek</Link>
                <Link to='/csapatok-es-Merkozesek' class='hover:text-bja-secondary'>Csapatok és Mérkőzések</Link>
                <Link to='/programok' class='hover:text-bja-secondary'>Programok</Link>
                <Link to='/kapcsolat' class='hover:text-bja-secondary'>Kapcsolat</Link>
            </div>

            <div class='h-full flex flex-col items-center text-center leading-9 md:text-right md:items-end'>
                <Link to='/' class='m-2'><img src={logo} alt='BJA logo' className="h-[95px] w-[95px] rounded-full" /></Link>
                <p class='leading-normal'>Tüskecsarnok<br />1117 Buapest<br />Magyar Tudósok Körútja 7</p>
                <Link to='mailto: akademia@bja.sport.hu' class='text-bja-secondary hover:text-white'>akademia@bja.sport.hu</Link>
                <a class='text-sm text-gray-400 normal-case underline' href='/BJA_Adatkezelesi tajekoztato.pdf' download>Adatkezelési tájékoztató letöltése</a>
                <div className="p-[10px] w-[95px] flex justify-around items-center">

                    <a href="https://www.facebook.com/hokiakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className="h-7 text-bja-secondary hover:text-white" /></a>
                    <a href="https://www.instagram.com/budapestjegkorongakademia/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className="h-7 text-bja-secondary hover:text-white" /></a>
                </div>
            </div>

        </footer>
    </>


}