
import NewsBanner from "../components/NewsBanner";
import placeholder from '../assets/placeholder.png'
import AboutUs from '../components/AboutUs'


function HomePage() {
    return (
        <div className="bg-bja-primary h-full">
            <div class='w-full h-[350px] sm:h-[450px] md:h-[600px] lg:h-[calc(100vh-50px)] max-h-[900px]'>
                <img alt='' src={placeholder} className='h-full w-full object-cover' />
            </div>
            <NewsBanner />
            <AboutUs />
      
      
      
    </div>
  );
}

export default HomePage;
